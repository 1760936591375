import {
  ResultTypes,
  ISearchResult,
} from 'src/components/search/SearchSingleResult'
import allIcon from 'src/images/icons/search/all.inline.svg'
import documentsIcon from 'src/images/icons/search/documents.inline.svg'
import lawsOfTheGameIcon from 'src/images/icons/search/laws-of-the-game.inline.svg'
import newsIcon from 'src/images/icons/search/news.inline.svg'
import theIfabIcon from 'src/images/icons/search/the-ifab.inline.svg'
import changesAndTrialsIcon from 'src/images/icons/search/changes-and-trials.inline.svg'
import allIconBold from 'src/images/icons/search/all-bold.inline.svg'
import documentsIconBold from 'src/images/icons/search/documents-bold.inline.svg'
import lawsOfTheGameIconBold from 'src/images/icons/search/laws-of-the-game-bold.inline.svg'
import newsIconBold from 'src/images/icons/search/news-bold.inline.svg'
import theIfabIconBold from 'src/images/icons/search/the-ifab-bold.inline.svg'
import changesAndTrialsIconBold from 'src/images/icons/search/changes-and-trials-bold.inline.svg'
import { ITab } from 'src/components/ui/Tabs'

export interface ISearchCategory extends ITab {
  type: Array<ResultTypes>
  searchResults?: ISearchResult[]
}

const defaultTabs: ISearchCategory[] = [
  {
    id: 'all',
    icon: allIcon,
    boldIcon: allIconBold,
    label: 'All',
    type: [...Object.values(ResultTypes)],
  },
  {
    id: 'laws-of-the-game',
    icon: lawsOfTheGameIcon,
    boldIcon: lawsOfTheGameIconBold,
    label: 'Laws of the Game',
    type: [
      ResultTypes.lawSection,
      ResultTypes.glossary,
      ResultTypes.staticPage,
    ],
  },
  {
    id: 'changes-and-trials',
    label: 'Changes and Trials',
    icon: changesAndTrialsIcon,
    boldIcon: changesAndTrialsIconBold,
    type: [ResultTypes.trials, ResultTypes.lawChange],
  },
  {
    id: 'the-ifab',
    icon: theIfabIcon,
    boldIcon: theIfabIconBold,
    label: 'The IFAB',
    type: [
      ResultTypes.theIfabBackground,
      ResultTypes.workProcess,
      ResultTypes.organisation,
    ],
  },
  {
    id: 'documents',
    icon: documentsIcon,
    boldIcon: documentsIconBold,
    label: 'Documents',
    type: [ResultTypes.document],
  },
  {
    id: 'news',
    icon: newsIcon,
    boldIcon: newsIconBold,
    label: 'News',
    type: [ResultTypes.news],
  },
]

export default defaultTabs
