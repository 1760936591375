// i18next-extract-mark-ns-start search

import React, { FC, useState, useEffect } from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from 'src/../tailwind.config'
import { useTranslation } from 'react-i18next'

const tailwind = resolveConfig(tailwindConfig)

export interface ITab {
  content?: JSX.Element | JSX.Element[] | string
  id: string
  icon: FC
  boldIcon: FC
  label: string
}

interface ITabsProps {
  tabs: ITab[]
}

const StyledHeadingsContainer = styled.div`
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    border: 0px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: none;
  }
`

const StyledHeading = styled.button`
  &:last-child {
    ${tw`mr-0`}
  }
`
const OverlayShadow = styled.div`
  ${tw`absolute top-0 bottom-0 w-1 -right-1`}
  box-shadow: 0px 0px 38px 21px ${tailwind.theme.colors.deepDarkBlue};
`

const StyledLabel = styled.div`
  &:after {
    content: attr(data-text);
    content: attr(data-text) / '';
    height: 0;
    visibility: hidden;
    overflow: hidden;
    user-select: none;
    pointer-events: none;
    font-weight: bold;

    @media speech {
      display: none;
    }
  }
`

const Tabs: FC<ITabsProps> = ({ tabs }) => {
  const { t } = useTranslation('search')
  const [activeTabId, setActiveTabId] = useState(null)

  const handleTabClick = (tab: ITab) => {
    setActiveTabId(tab.id)
  }

  const selectFirstTabAsDefault = () => {
    setActiveTabId(tabs[0].id)
  }

  const getCurrentTab = () => tabs.find((tab) => tab.id === activeTabId)

  useEffect(() => {
    selectFirstTabAsDefault()
  }, [])

  const isActive = (tab: ITab) => activeTabId === tab.id

  const Headings = () =>
    tabs.map((tab) => (
      <StyledHeading
        key={tab.id}
        className={`
        pr-3 mr-0.5 cursor-pointer transition-all duration-300 whitespace-nowrap text-center flex-grow flex items-center
        ${
          isActive(tab)
            ? 'bg-almostBlackBlue text-brightYellow'
            : 'bg-paleBlue hover:bg-brightYellow'
        }
        `}
        onClick={() => handleTabClick(tab)}
        type="button"
      >
        <tab.icon
          className={`
            w-8 icon  ${
              isActive(tab)
                ? 'hidden'
                : 'md:hidden lg:block xl:hidden 2xl:block'
            }`}
        />
        <tab.boldIcon
          className={`w-8 icon  ${
            isActive(tab) ? 'md:hidden lg:block xl:hidden 2xl:block' : 'hidden'
          }`}
        />
        <StyledLabel
          className={`md:pl-2 lg:pl-0 xl:pl-2 2xl:pl-0 flex flex-col ${
            isActive(tab) ? 'font-bold' : ''
          }`}
          data-text={t(tab.label)}
        >
          {t(tab.label)}
        </StyledLabel>
      </StyledHeading>
    ))

  return (
    <>
      <div className="relative overflow-hidden">
        <StyledHeadingsContainer className="relative flex flex-no-wrap border-b-2 border-almostBlackBlue overflow-y-hidden overflow-x-auto md:overflow-y-visible">
          {Headings()}
        </StyledHeadingsContainer>
        <OverlayShadow className="md:hidden" />
      </div>
      <>{getCurrentTab() && getCurrentTab().content}</>
    </>
  )
}

export default Tabs
