import React, { FC } from 'react'
import SingleResult, {
  ISearchResult,
} from 'src/components/search/SearchSingleResult'

interface ISearchResultsProps {
  results: ISearchResult[]
}

const SearchResults: FC<ISearchResultsProps> = ({ results }) => {
  const searchResults = results.map((singleResult) => (
    <SingleResult result={singleResult} key={singleResult.objectId} />
  ))
  return <div>{searchResults}</div>
}

export default SearchResults
