import axios from 'axios'
import { ResultTypes } from 'src/components/search/SearchSingleResult'

export interface ISearchParams {
  locale: string
  page?: number
  pageSize?: number
  query: string
  season: string
  type: ResultTypes[]
}

const defaultParams: ISearchParams = {
  locale: 'en',
  season: '2020-21',
  query: 'ball',
  type: [ResultTypes.lawSection],
}

export interface ISearchResultResponse {
  hasNextPage: boolean
  hits: [
    {
      content: string
      hitId: string
      objectId: string
      slug: string
      title: string
      type: ResultTypes
    }
  ]
  nextPage: number
}

const fetchSearchResults = (searchParams: ISearchParams) => {
  const requestParams = {
    ...defaultParams,
    ...searchParams,
  }

  return axios.post<ISearchResultResponse>(
    'https://web2-backend.theifab.com/api/search',
    requestParams
  )
}

export default fetchSearchResults
