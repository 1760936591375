// i18next-extract-mark-ns-start search

import React, { FC, useEffect, useState, useContext, useRef } from 'react'
import { AxiosResponse } from 'axios'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import PageTitle from 'src/components/typography/PageTitle'
import Section from 'src/components/typography/Section'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import Tabs, { ITab } from 'src/components/ui/Tabs'
import SearchResults from 'src/components/search/SearchResults'
import fetchSearchResults, { ISearchResultResponse } from 'src/services/search'
import defaultTabs, {
  ISearchCategory,
} from 'src/components/search/searchCategories'
import lawsStore from 'src/components/laws/laws-store'
import { getSeasonFromLocalStorage } from 'src/helpers/seasons'

interface ISearchPageProps {}

const StyledInput = styled.input`
  box-shadow: 0px 4px 18px -4px #b5b5b5;
`

const SearchPage: FC<ISearchPageProps> = () => {
  const { t } = useTranslation('search')
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [tabs, setTabs] = useState<Array<ISearchCategory>>(defaultTabs)
  const lngContext = useContext(I18nextContext)
  const selectedSeason = lawsStore((state) => state.selectedSeason)
  const searchInputRef = useRef(null)
  const isBrowser = typeof window !== 'undefined'

  const inputValueRef = useRef(inputValue)
  const setInputValueRef = (newValue) => {
    inputValueRef.current = newValue
    setInputValue(newValue)
  }

  const getQueryFromUrl = () => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())

    return params.query
  }

  const assignSearchResultsToTabs = (
    responses: AxiosResponse<ISearchResultResponse>[]
  ) => {
    const tabsWithUpdatedSearchResults: ISearchCategory[] = tabs.map(
      (tab, tabIndex) => {
        const { data } = responses[tabIndex]

        return {
          ...tab,
          searchResults: data.hits,
        }
      }
    )

    setTabs(tabsWithUpdatedSearchResults)
  }

  const getCurrentSeason = () => {
    if (getSeasonFromLocalStorage()) {
      return getSeasonFromLocalStorage()
    }

    return selectedSeason
  }

  const requestAndAssignResultsForAllTabs = (query: string) => {
    const currentSeason = getCurrentSeason()

    const requests = tabs.map((tab) =>
      fetchSearchResults({
        locale: lngContext.language,
        query,
        season: currentSeason.remoteSlug,
        type: tab.type,
      })
    )

    return Promise.all(requests).then((searchResultResponses) => {
      assignSearchResultsToTabs(searchResultResponses)
    })
  }

  const getSearchResults = () => {
    setIsLoading(true)
    requestAndAssignResultsForAllTabs(inputValueRef.current).finally(() => {
      setIsLoading(false)
    })
  }

  if (isBrowser) {
    useEffect(() => {
      const query = getQueryFromUrl() || ''

      setInputValueRef(query)
      getSearchResults()
    }, [window.location.search, selectedSeason])
  }

  const handleChange = (e) => {
    setInputValueRef(e.target.value)
  }

  const mapSearchResultsToComponents = (
    tabsToMap: ISearchCategory[]
  ): Array<ITab> =>
    tabsToMap.map((tab) => ({
      ...tab,
      content:
        tab.searchResults && tab.searchResults.length ? (
          <SearchResults results={tab.searchResults} />
        ) : (
          <div className="my-4">
            {isLoading ? `${t('Loading')}...` : t('No results.')}
          </div>
        ),
    }))

  const handleFormSubmit = (e) => {
    getSearchResults()
    e.preventDefault()
  }

  return (
    <>
      <Helmet>
        <title>{t('Search result')}</title>
      </Helmet>
      <Layout>
        <div className="min-h-screen bg-white text-deepDarkBlue">
          <ContentPageContainer>
            <Section>
              <PageTitle>
                <span>{t('Search result')}</span>
              </PageTitle>
              <form onSubmit={handleFormSubmit}>
                <StyledInput
                  value={inputValue}
                  onChange={handleChange}
                  ref={searchInputRef}
                  className="h-10 px-4 text-lg border border-paleBlue w-full outline-none"
                />
              </form>
              <div className="mt-12">
                <Tabs tabs={mapSearchResultsToComponents(tabs)} />
              </div>
            </Section>
          </ContentPageContainer>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default SearchPage
